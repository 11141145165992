import Typography from '@mui/material/Typography';
import React from 'react';
import { Button } from '../../../components/Button';

import { Box, Link } from '@mui/material';
import { useAsyncFn } from 'react-use';
import { Modal } from '../../../components/Modal';
import API from '../../../services/Api';
import { logError } from '../../../services/Logger';

const UpdatedTermsModal = () => {
  // /** returns true if successfull */
  const [{ loading, error, value: isUpdateSuccessful }, submitUpdateRequest] =
    useAsyncFn(async () => {
      try {
        await API.AcceptTerms();
        return true;
      } catch (error: unknown) {
        logError('Could not accept updated terms', 'ALERT', error);
        const errorMessage = 'There was an error handling the request';
        throw new Error(errorMessage);
      }
    }, []);

  return (
    <Modal center open={!isUpdateSuccessful} dissmissable={false}>
      <Typography variant="h2" component="h2" mb={2}>
        {'Updated Terms and Privacy Policy'}
      </Typography>
      <Typography variant="body1" component="span" mb={2}>
        {
          'In order to continue to access the Esusu App, we need you to read and agree to our updated '
        }
        <Link
          href="https://esusurent.com/terms-and-conditions/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {'Terms & Conditions'}
        </Link>
        {' and '}
        <Link
          href="https://esusurent.com/privacy-policy/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {'Privacy Policy'}
        </Link>
        {
          '. By clicking "Agree and Continue" below, you confirm that you agree to these updated versions.'
        }
      </Typography>

      <Box display="flex" justifyContent="flex-end" mt={4}>
        <Button variant="primary" disabled={loading} onClick={submitUpdateRequest}>
          {'Agree and Continue'}
        </Button>
      </Box>
      {error?.message && <Typography>{error.message}</Typography>}
    </Modal>
  );
};

export default UpdatedTermsModal;
