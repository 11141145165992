import { useAuth0 } from '@auth0/auth0-react';
import { useCallback, useMemo } from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import { useNavigate } from 'react-router-dom';
import { getIfErrorHasStatusValue } from '../helpers/objectUtils';
import { ErrorLevels, logError } from '../services/Logger';
import type { GetAccountInfoOptions } from '../services/QueryModel';
import { QueryModel } from '../services/QueryModel';

interface ErrorHandlingOptions {
  404?: {
    /**
     * If we are expecting the account to not be there
     * - For pages in the account creation flow
     */
    redirectToSignUp?: boolean;
    redirectToSelectStatus?: boolean;
  };
  401?: {
    redirectToVerify?: boolean;
  };
  500?: {
    /** @default true */
    showErrorBoundary?: boolean;
  };
}

interface UseAuthenticatedUserAccountInfoOptions {
  queryOptions?: GetAccountInfoOptions;
  /** toggles prepared error handling for certain error cases */
  errorHandlingOptions?: ErrorHandlingOptions;
}

/**
 *
 * @param options - the options to pass to `useQuery` and for handling certain error scenarios
 * @returns the response values from auth0 and the get user info request
 */
export const useAuthenticatedUserAccountInfo = ({
  queryOptions,
  errorHandlingOptions,
}: UseAuthenticatedUserAccountInfoOptions = {}) => {
  // pulling these boolean options out so any unmemoized option objects don't trigger rerenders
  const { redirectToSignUp, redirectToSelectStatus } = errorHandlingOptions?.[404] ?? {};
  const { redirectToVerify } = errorHandlingOptions?.[401] ?? {};
  const { showErrorBoundary = true } = errorHandlingOptions?.[500] ?? {};
  const navigate = useNavigate();
  const { showBoundary } = useErrorBoundary();
  const { isLoading, isAuthenticated, loginWithRedirect, ...rest } = useAuth0();

  const memoizedErrorFromOptions = useMemo(() => queryOptions?.onError, [queryOptions]);

  const handleError = useCallback(
    (err: unknown) => {
      const logErrorAndRedirect = async () => {
        if (getIfErrorHasStatusValue(err, 404)) {
          if (redirectToSignUp) {
            // is a new user and we are expecting an error
            navigate('/sign-up');
            return;
          }
          if (redirectToSelectStatus) {
            logError('Returned 404 Error from GetAccountInfo', ErrorLevels.INFO, err);
            navigate('/select-status');
          }
        } else if (getIfErrorHasStatusValue(err, 401) && redirectToVerify) {
          logError('unauthorized when getting user info', ErrorLevels.INFO, err);
          await loginWithRedirect({
            redirectUri: `${window.location.origin}/verify`,
          });
        } else if (getIfErrorHasStatusValue(err, 500) && showErrorBoundary) {
          // no clue what happened
          logError('unknown error fetching account info', 'ERROR', err);
          // show error boundary
          showBoundary(err);
        }
      };

      memoizedErrorFromOptions?.(err);
      logErrorAndRedirect();
    },
    [
      loginWithRedirect,
      navigate,
      memoizedErrorFromOptions,
      showBoundary,
      redirectToSignUp,
      redirectToSelectStatus,
      redirectToVerify,
      showErrorBoundary,
    ],
  );

  const response = QueryModel.GetAccountInfo({
    ...queryOptions,
    /** make sure to include the incoming `enabled` value if it is actually defined */
    enabled: (queryOptions?.enabled === undefined ? true : queryOptions.enabled) && !isLoading,
    onError: handleError,
  });

  const mustAcceptUpdatedTerms = useMemo(() => {
    return response.data?.termsAcceptanceStatus === false;
  }, [response.data]);

  return {
    ...response,
    mustAcceptUpdatedTerms,
    isLoading: isLoading || response.isLoading,
    isAuthenticated,
    loginWithRedirect,
    ...rest,
  };
};
