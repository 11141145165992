import { Modal as EsusuModal } from '@esusu2017/component-library';
import React from 'react';
import './modal.scss';

interface CustomCenteredModal extends React.ComponentProps<typeof EsusuModal> {
  /**
   * centers the modal
   */
  center?: boolean;
  /**
   * hides the close button if not
   * @default true
   */
  dissmissable?: boolean;
}

const CENTER_STYLING: React.CSSProperties = {
  top: '50%',
  right: '50%',
  transform: 'translate(50%,-50%)',
};

/**
 * Extends the Esus Component library modal to add updated
 * styling to correctly center it
 */
const Modal = ({ center, dissmissable = true, dialogProps, ...props }: CustomCenteredModal) => {
  const dialogStyle = center ? CENTER_STYLING : {};
  return (
    <EsusuModal
      dialogProps={{
        ...dialogProps,
        className: !dissmissable ? 'EsusuModal-dialog' : undefined,
        style: {
          ...dialogProps?.style,
          ...dialogStyle,
        },
      }}
      {...props}
    />
  );
};

export { Modal };
