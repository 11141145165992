import type { ButtonProps } from '@esusu2017/component-library';
import { Button as EsusuButton, deepGreen } from '@esusu2017/component-library';
import type { Theme } from '@mui/material/styles';
import { darken, lighten, styled, useTheme } from '@mui/material/styles';
import { CSSProperties, CSSPseudos } from '@mui/styled-engine';
import React from 'react';
import type { ThemedSxProps } from '../../helpers/buildSxProps';

const TEMPORARY_PRIMARY_BUTTON_GREEN = '#008630';

/**
 * Build some style overrides for custom button colors
 */
const buildCustomCardButtonStyling = (baseColor?: string): ThemedSxProps => {
  if (!baseColor) return {};
  const lightenedColor = lighten(baseColor, 0.2);
  const darkenedColor = darken(baseColor, 0.2);
  return {
    backgroundColor: baseColor,
    ':hover': {
      backgroundColor: lightenedColor,
      outlineColor: darkenedColor,
    },
    ':active': {
      backgroundColor: lightenedColor,
      outlineColor: darkenedColor,
    },
    ':focus': {
      backgroundColor: lightenedColor,
      outlineColor: darkenedColor,
    },
  };
};

const getDisabledColor = (
  variant: NonNullable<ButtonProps['variant']>,
  theme: Theme,
): CSSProperties & CSSPseudos => {
  let backgroundColor = theme.palette.primary.lighter;
  let color = theme.palette.primary.main;
  if (variant === 'secondary') {
    backgroundColor = deepGreen[200];
    color = deepGreen[700];
  }
  return {
    ':disabled': {
      backgroundColor,
      color,
      outline: 'none',
    },
  };
};

/**
 * Extends the Esus Component library button to add a `disabled` state
 */
const ButtonBase = styled(EsusuButton)<ButtonProps>(({ theme, variant = 'primary' }) => ({
  backgroundColor: variant === 'primary' ? TEMPORARY_PRIMARY_BUTTON_GREEN : undefined,
  ':disabled': {
    ...theme.outline.primary,
    ...getDisabledColor(variant, theme),
  },
}));

interface CustomColorButton extends ButtonProps {
  /**
   * hex color code or `'error'`
   */
  baseColor?: string;
}

/**
 * Extends the Esus Component library button to add a `disabled` state
 * - also adds custom color options
 */
const Button = ({ baseColor, ...props }: CustomColorButton) => {
  const theme = useTheme();
  let sxProps: ThemedSxProps = {};
  if (baseColor) {
    let colorCodeToUse = baseColor;
    if (baseColor === 'error') {
      colorCodeToUse = theme.palette.error.main;
    }
    sxProps = buildCustomCardButtonStyling(colorCodeToUse);
  }

  return <ButtonBase sx={sxProps} {...props} />;
};

export { Button };
